/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ActiveEffectButton,
  NiagaraButton2,
  NiagaraCard,
  NiagaraSearch,
  NiagaraTooltip,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import Status from '../../utils/Status';
import { useProjectStore } from '../UseProjectStore';
import { DataTable } from '@scuf/datatable';
import '@scuf/datatable/honeywell/theme.css';
import { useDevicesPageAndSearch } from '../UseDevicesPageAndSearch';
import { IPageSearchSortOptions } from '../IPageSearchSortOptions';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { DeleteProjectModal } from '../ProjectListSidebarEntry';
import { showErrorToast, showSuccessToast, ToastActions } from '../../toast/Toast';
import { GetFailedResponseMessage, GetSuccessResponseMessage } from '../EditDeviceDetails/PopupMessages';
import { ScrollIntoView } from '../../components/ScrollIntoView/ScrollIntoView';
import { showSnackBarProp, SnackBarProp } from '../../components/TypeDefinitions';
import { Device, Project } from '../../api/management';
import { DeviceWithProjectIdAndSubscriptions } from '../ProjectStore';
import { NiagaraDataTable } from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib/lib/table';
import { tagTimedEvent } from '../../utils/localytics/useAnalytics';
import { usePermissions } from '../../userroles/usePermissions';
import { useHistoryState } from '../../utils/useHistoryState';
import HeaderCell, { HeaderCellProps } from '../../components/HeaderCell/HeaderCell';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { getDeviceRemoteUrl } from '../../utils/getDeviceRemoteUrl';
import { EditDeviceDetailsModal } from '../EditDeviceDetails/EditDeviceDetailsModal';
import { UsageMetricsModalV2 } from '../usageMetricsModal/UsageMetricsModalV2';
import { ReRegisterDeviceModal } from '../DeviceRegistration/ReRegisterDeviceModal';
import { reRegisterDeviceAsync } from '../../api/registerDeviceAsync';
import { useCustomerStore } from '../../customer';
import './ResponsiveDeviceList.scss';
import { Icon } from '@scuf/common';
import LoadMoreButton from '../../components/LoadMoreButton/LoadMoreButton';
import { useResponsive } from '../../components/ResponsiveHook';
import { ShowColumnsModal } from './ShowColumnsModal';
import { ProjectListModal } from './ProjectListModal';
export interface IDeviceUpdateNotification {
  newDevicePromise: Promise<Device>;
  updatedFields: {
    projectId?: number;
    projectName?: string;
    location?: string;
  };
}
const ITEMS_PER_PAGE = 10;
const DEVICE_FIELDS_CHECKED_DEFAULT = [
  'Device',
  'Location',
] as const;


export default function ResponsiveDeviceList({
  SnackBar,
  showSnackBar,
}: {
  SnackBar: SnackBarProp;
  showSnackBar: showSnackBarProp;
}) {
  const { currentProject, getProjectsAndDevicesAsync, setCurrentProject, setCurrentDevice } =
    useProjectStore();
  const [highlightedDeviceId, setHighlightedDeviceId] = useState<number>();
  const [projectToDelete, setProjectToDelete] = useState<Project>();
  const [deviceToShowUsage, setDeviceToShowUsage] = useState<DeviceWithProjectIdAndSubscriptions>();
  const [deviceToReregister, setDeviceToReregister] = useState<DeviceWithProjectIdAndSubscriptions>();
  const [selectedDevice, setSelectedDevice] = useState<DeviceWithProjectIdAndSubscriptions>();
  const [deviceToEdit, setDeviceToEdit] = useState<DeviceWithProjectIdAndSubscriptions>();
  const { enableDeviceDetailsPage, enableHistoryCharting } = useFlags();
  const { responsiveDevice } = useResponsive();
  const [settings, setSettings] = useHistoryState<{
    projectId?: string;
    page?: number;
  }>();
  const [expandedRows, setExpandedRows] = useState<any>([]);
  const [count, setCount] = useState<any>();
  const [options, setOptions] = useState<IPageSearchSortOptions<Device>>(() => {
    return {
      keyword: undefined,
      page: (currentProject?.id === settings.projectId ? settings.page : null) ?? 0,
      size: ITEMS_PER_PAGE,
      sort: ['deviceName'],
      order: 'asc',
    };
  });
  const { status = 'idle', page, devices = [], findDevicePage, error } = useDevicesPageAndSearch(options);
  const [devicesArray, setDevicesArray] = useState<any>([]);
  const [showColumnModal, setShowColumnModal] = useState(false);

  const goToPage = useCallback((page: number) => {
    setOptions((o) => ({ ...o, page }));
    setSettings({ page });
  }, []);
  const onPageChange = useCallback((page: string | number) => goToPage(parseInt(page.toString(), 10) - 1), []);
  const setKeyword = useCallback((keyword?: string) => setOptions((o) => ({ ...o, keyword })), []);
  const setOrder = useCallback(
    (order: string) => setOptions((o) => ({ ...o, order: order === 'asc' ? 'asc' : 'desc' })),
    []
  );
  const setSortBy = useCallback((fieldName: keyof Device) => setOptions((o) => ({ ...o, sort: [fieldName] })), []);

  const [currentPage, setCurrentPage] = useState(page?.number ? page?.number : 0);
  const ldc = useLDClient();
  const [enabledDevicesforAlarms, setEnabledDevicesforAlarms] = useState<string[]>();
  const [projectsList, setProjectsList] = useState(false);
  const [apiStatus, setApiStatus] = useState(status);

  const defaultDeviceColumns: ([string, string] | [string, string, React.FC<any>])[] = [
    ['Device', 'deviceName', (device) => deviceNameRenderer(device, highlightedDeviceId, enableDeviceDetailsPage, setCurrentDevice)],
    ['Location', 'location'],
    ['Host ID','hostId'],
    ['Projects', 'projectName'],
    ['Service', 'service', (data) => <Services {...{ device: data, enabledDevicesforAlarms: enabledDevicesforAlarms || [], setCurrentDevice, enableHistoryCharting }} />],
  ];

  function GetDefaultDeviceColumns(): {
    label: string;
    checked: boolean;
    disabled: boolean;
    header: string;
    field: string;
    renderer: (({ rowData }: { rowData: any }) => JSX.Element) | undefined;
  }[] {
    return defaultDeviceColumns.map(([label, field, CellRenderer]) => ({
      label,
      checked: DEVICE_FIELDS_CHECKED_DEFAULT.some((x) => x === label),
      disabled: !DEVICE_FIELDS_CHECKED_DEFAULT.some((x) => x === label),
      header: label,
      field,
      renderer: CellRenderer ? ({ rowData }: { rowData: any }) => <CellRenderer {...rowData} /> : undefined,
    }));
  }

  useEffect(() => {
    status !== 'rejected' && setApiStatus(status);
  },[status]);

  useEffect(() => {
    if (!page) return;
    goToPage(findDevicePage(highlightedDeviceId) ?? page?.number);
  }, [highlightedDeviceId, page?.number]);

  useEffect(() => {
    const enabled: string[] = [];
    let newDevice: any = [];
    if (devicesArray.length > 0 && currentPage > 0) {
      newDevice = [...devicesArray];
    }
    if (
      devices &&
      devices?.length > 0
    ) {
      newDevice.push(
        ...devices.filter(
          (p) => !devicesArray.includes(p)
        )
      );
    }
    setDevicesArray(newDevice);
    devices.map((d, i) => {
      return ldc
        ?.identify({
          kind: 'user',
          key: d.id.toString(),
          deviceUuid: d.deviceUuid,
        })
        .then((allFlags) => {
          if (allFlags.enableDeviceAlarms) {
            enabled.push(d.deviceUuid);
          }
        })
        .finally(() => {
          setEnabledDevicesforAlarms([...enabled]);
        });
    });
  }, [devices.length, currentPage, options.keyword]);

  const hasDevices =
    (devices && devices.length > 0) ||
    (!currentProject?.devices && options.keyword) ||
    (currentProject?.devices && currentProject?.devices?.length > 0);

  const onDeviceDetailsUpdate = ({ newDevicePromise, updatedFields }: IDeviceUpdateNotification) => {
    const { projectName } = updatedFields;
    newDevicePromise
      .then((updatedDevice) => {
        setHighlightedDeviceId(updatedDevice.id);
        const message = GetSuccessResponseMessage(updatedDevice, updatedFields);
        !projectName
          ? showSnackBar(<div className='device-update-snackbar-message'>{message}</div>)
          : showSuccessToast(
            '',
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='device-update-toast-message'>{message}</div>
              <ToastActions
                secondaryText='View Device'
                secondaryAction={() => {
                  setCurrentProject(updatedFields.projectId);
                  setSettings({
                    projectId: updatedFields.projectId,
                    page: 0,
                  });
                  setHighlightedDeviceId(updatedDevice.id);
                }}
                secondaryButtonPosition='right'
              />
            </div>
          );
      })
      .catch(() => {
        const message = GetFailedResponseMessage(updatedFields);
        !projectName
          ? showSnackBar(
            <div style={{ display: 'flex', gap: '10px' }}>
              <div className='device-update-snackbar-message'>{message}</div>
              <NiagaraButton2
                className='device-update-snackbar-retry-button'
                type='primary-link'
                content='Retry'
                onClick={() => {
                  onDeviceDetailsUpdate({ newDevicePromise, updatedFields });
                }}
              />
            </div>,
            0
          )
          : showErrorToast(
            '',
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='device-update-toast-message'>{message}</div>
              <NiagaraButton2
                className='device-update-toast-retry-button'
                type='primary-link'
                content='Retry'
                onClick={() => {
                  onDeviceDetailsUpdate({ newDevicePromise, updatedFields });
                }}
              />
            </div>
          );
      });
    setDeviceToEdit(undefined);
  };
  const DevicePermissions = usePermissions().Management.Devices;
  const headerProps: Partial<HeaderCellProps<Device>> = {
    order: options.order,
    setSortBy,
    setOrder,
    sortBy: options.sort?.[0] as keyof Device,
  };
  const expanderTemplate = (data: any) => {
    const open = expandedRows?.includes(data.rowData);
    return (
      <div onClick={() => toggleExpand(data.rowData, open)}>
        <Icon root='common' name={open ? 'caret-up' : 'caret-down'} />
      </div>
    );
  };
  const toggleExpand = (data: any, open: boolean) => {
    let expanded = expandedRows;
    if (open) {
      let index = expanded?.findIndex(
        (item: any) => JSON.stringify(item) === JSON.stringify(data)
      );
      expanded?.splice(index, 1);
    } else {
      expanded?.push(data);
    }
    let oldCount = count ? count : 0;
    setCount(oldCount + 1);
    setExpandedRows(expanded);
  };
  const rowExpansionTemplate = (data: any) => {
    return (
      <>
      {GetDefaultDeviceColumns().map(({ header, field, renderer }, index) =>
          responsiveDevice === 'Tablet' ||
          responsiveDevice === 'iPad Air' ||
          (responsiveDevice === 'Mobile' && index > 1) ||
          (responsiveDevice === 'Tablet Pro' && index > 2) ? (
            <div className='sub-fields' key={`sub-fields-${index}`}>
              <div className='sub-field-header'>{header}</div>
              <div className='sub-field-data'>
                <span className='sub-field-text'>
                  {renderer ? renderer({ rowData: data }) : data[field]}
                </span>
              </div>
            </div>
          ) : null
        )}
      </>
    );
  };

  const paginationSettings = {
    onPageChange: (e: any) => {
      onPageChange(e);
      highlightedDeviceId && setHighlightedDeviceId(undefined);
      selectedDevice && setSelectedDevice(undefined);
    },
    totalItems: page?.totalElements ?? 0,
    itemsPerPage: page?.size ?? ITEMS_PER_PAGE,
    activePage: (page?.number ?? 0) + 1,
  }

  const updatePageNumber = () => {
    setCurrentPage(currentPage + 1);
    onPageChange(currentPage + 1);
  };

  return (
    <NiagaraCard className='mobile-responsive-device-table'>
      <SnackBar />
      <div className='device-list-header'>
        <>
          <div className='flex-fill'>
          {hasDevices && (
            <NiagaraSearch
              placeholder='Search'
              value={options.keyword}
              results={[]}
              onSearchChange={(val: string) => {
                responsiveDevice !== 'Desktop' && responsiveDevice !== 'Tablet Pro' && setDevicesArray([]);
                setKeyword(val);
              }}
            />
          )}
          </div>
          <div className='device-filters'>
            <ActiveEffectButton
              type='tertiary'
              icon='Filter'
              className='filter-button'
              onClick={() => setProjectsList(true)}
            />
            <ActiveEffectButton
              type='tertiary'
              icon='ColumnAction'
              className='columnaction-button'
              iconSize={16}
              onClick={() => setShowColumnModal(true)}
            />
          </div>
        </>
      </div>
      <Status status={apiStatus} error={error} onRefresh={getProjectsAndDevicesAsync}>
        {hasDevices && devicesArray ? (
          <>
            <NiagaraDataTable
              paginationSettings={paginationSettings}
              data={devicesArray}
              enableLazyPagination={true}
              showItemCountAtBottom={true}
              tableName='Device'
              hidePagination={true}
              rowExpansionTemplate={rowExpansionTemplate}
              expandedRows={expandedRows}
              className='mobile-device-table'
            >
              {GetDefaultDeviceColumns().map(
                ({ header, field, renderer }, index) =>
                  ((responsiveDevice === 'Tablet' && index <= 1) ||
                    (responsiveDevice === 'iPad Air' && index <= 1) ||
                    (responsiveDevice === 'Mobile' && index <= 1) ||
                    (responsiveDevice === 'Tablet Pro' && index <= 2) ||
                    responsiveDevice === 'Desktop') && (
                    <DataTable.Column
                      key={header}
                      initialWidth={'45%'}
                      header={
                        header === 'services' ? (
                          header
                        ) : (
                          <HeaderCell
                            name={header}
                            //@ts-ignore
                            sortOnField={field}
                            {...headerProps}
                          />
                        )
                      }
                      {...{ field }}
                      renderer={(rowData) => (
                        <>
                          {renderer ? (
                            <div className='cell-data-val'>{renderer(rowData)}</div>
                          ) : (
                            <div className='cell-data-val'>{rowData.rowData[field]}</div>
                          )}
                          {(((responsiveDevice === 'Tablet' ||
                            responsiveDevice === 'iPad Air' ||
                            responsiveDevice === 'Mobile') &&
                            index === 1) ||
                            (responsiveDevice === 'Tablet Pro' && index === 2)) &&
                            expanderTemplate(rowData)}
                        </>
                      )}
                    />
                  )
              )}
            </NiagaraDataTable>

            <LoadMoreButton
              data={devicesArray}
              totalElements={paginationSettings?.totalItems}
              isLoading={status === 'pending' ? true : false}
              updatePageNumber={updatePageNumber}
            ></LoadMoreButton>
            {deviceToShowUsage ? (
              <UsageMetricsModalV2
                device={deviceToShowUsage}
                onClose={() => {
                  setDeviceToShowUsage(undefined);
                }}
              />
            ) : null}
            {deviceToReregister && (
              <ReRegisterDeviceModal
                device={deviceToReregister}
                onClose={() => {
                  setDeviceToReregister(undefined);
                }}
                onReRegisterDevice={(userCode: string, licenseId: string) => {
                  reRegisterDeviceAsync({
                    userCode,
                    deviceUuid: deviceToReregister.deviceUuid ?? '',
                    licenseId: parseInt(licenseId),
                  })
                    .then(() => showSuccessToast('Device re-registered.'))
                    .catch(() => {
                      showErrorToast(
                        'Device re-registration failed!',
                        <>Failed to re-register device. Please, try again after sometime</>
                      );
                    });
                  setDeviceToReregister(undefined);
                }}
              />
            )}
            {deviceToEdit && DevicePermissions.EditDevice(deviceToEdit) && (
              <EditDeviceDetailsModal
                device={deviceToEdit}
                onCancel={() => {
                  setDeviceToEdit(undefined);
                }}
                onUpdate={onDeviceDetailsUpdate}
              />
            )}
          </>
        ) : (
          <div className='device-list-empty'>
            <div className='device-list-empty-title'>No Devices Registered</div>
            <div className='device-list-empty-text'>
              Go to the devices registration page to generate a registration link
            </div>
            <NiagaraButton2
              onClick={() => {
                setProjectToDelete(currentProject);
              }}
              content='Delete Project'
              disabled={!currentProject}
              type='primary-link'
            />
            {projectToDelete && (
              <DeleteProjectModal
                projectToDelete={projectToDelete}
                onClose={() => {
                  setProjectToDelete(undefined);
                }}
              />
            )}
          </div>
        )}
      </Status>
      {showColumnModal && (
        <ShowColumnsModal
          header='Show columns'
          currentItems={GetDefaultDeviceColumns()}
          defaultItems={GetDefaultDeviceColumns()}
          onCancel={() => setShowColumnModal(false)}
          onSave={() => {}}
        />
      )}
      {projectsList && <ProjectListModal onClose={() => setProjectsList(false)} />}
    </NiagaraCard>
  );
}

const deviceNameRenderer = (
  device: Device,
  highlightedDeviceId: number | undefined,
  enableDeviceDetailsPage: boolean,
  setCurrentDevice: (device: DeviceWithProjectIdAndSubscriptions) => void
) => {
  const queryParams = new URLSearchParams({ deviceUuid: device.deviceUuid });
  return (
    <>
      {device.id === highlightedDeviceId && <span id='highlighted-device' />}
      <ScrollIntoView enable={device.id === highlightedDeviceId} />
      {enableDeviceDetailsPage ? (
        <NavLink className='navbar-item' to={`devicedetails?${queryParams}`} onClick={() => setCurrentDevice(device)}>
          {device.deviceName}
        </NavLink>
      ) : (
        device.deviceName
      )}
    </>
  );
}
const Services = ({
  device,
  enabledDevicesforAlarms,
  setCurrentDevice,
  enableHistoryCharting
}: {
  device: DeviceWithProjectIdAndSubscriptions;
  enabledDevicesforAlarms: string[];
  setCurrentDevice: (device: DeviceWithProjectIdAndSubscriptions) => void;
  enableHistoryCharting: boolean;
}) => {
  const navigate = useNavigate();
  const deviceRemoteUrl = useMemo(() => getDeviceRemoteUrl(device.deviceUuid), [device.deviceUuid]);
  const { enableAlarmsForCustomer } = useCustomerStore();
  const hasService = (service: string) => device.subscriptions.find((o) => o.offeringCode === service);
  const { NiagaraRemote, NiagaraDataServices } = usePermissions();
  const isNiagaraRemotePermissionPermitted = NiagaraRemote.Connect(device);
  const isNiagaraRecoverPermissionPermitted = usePermissions().NiagaraRecover.View(device);
  const isDataServicesQueryPermitted = NiagaraDataServices.QueryModel(device);
  const alarmPermission = usePermissions().NiagaraAlarms.Query(device);
  const location = useLocation();
  return (
    <div className='subscribed-services'>
      {enableAlarmsForCustomer && (
        <NiagaraTooltip
          className={`alternate-tooltip`}
          content='Alarms'
          position='bottom center'
          element={
            <ActiveEffectButton
              className='active-nds'
              type='primary-link'
              icon='Alert'
              iconSize={18}
              onClick={() => {
                const queryParams = new URLSearchParams({ deviceUuid: device.deviceUuid });
                setCurrentDevice(device);
                navigate(`devicedetails?tab=alarms&${queryParams}`);
              }}
              disabled={
                !hasService('NDS') || !alarmPermission || !enabledDevicesforAlarms.includes(device.deviceUuid)
              }
            />
          }
        />
      )}
      <NiagaraTooltip
        className={`alternate-tooltip`}
        content='Data Services'
        position='bottom center'
        element={
          <ActiveEffectButton
            className='active-nds'
            type='primary-link'
            icon='Trend'
            iconSize={18}
            disabled={!hasService('NDS') || !isDataServicesQueryPermitted}
            onClick={() => {
              setCurrentDevice(device);
              const queryParams = new URLSearchParams({ deviceUuid: device.deviceUuid });
              enableHistoryCharting ? (
                window.open(`${location.pathname}/devicedetails/history?${queryParams}`, '_self')
              ) : navigate('dataservice');
            }}
          />
        }
      />
      <NiagaraTooltip
        className='alternate-tooltip'
        content='Remote'
        position='bottom center'
        element={
          <ActiveEffectButton
            className='backup-link'
            type='primary-link'
            icon='Link'
            iconSize={18}
            disabled={!deviceRemoteUrl || !hasService('Remote') || !isNiagaraRemotePermissionPermitted}
            onClick={() => {
              const { complete } = tagTimedEvent('Niagara Remote');
              complete();
              window.open(deviceRemoteUrl, '_blank', 'noopener');
            }}
          />
        }
      />
      <NiagaraTooltip
        className='alternate-tooltip'
        content='Recover'
        position='bottom center'
        element={
          <ActiveEffectButton
            className='backup-link'
            type='primary-link'
            icon='UploadCloud'
            iconSize={18}
            disabled={!hasService('Recover') || !isNiagaraRecoverPermissionPermitted}
            onClick={() => {
              setCurrentDevice(device);
              navigate('savedbackups');
            }}
          />
        }
      />
    </div>
  );
}
